<template>
  <BaseItemForm
    v-slot="{ cancel, itemIsNew }"
    :item="item"
    :category="category"
  >
    <b-row align-v="center">
      <b-col>
        <b-row>
          <b-col>
            <p class="form-control-label">
              <strong v-if="itemIsNew(item)">Ajouter la suggestion</strong>
              <strong v-else>Modifier la suggestion</strong>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="h5 mb-0">
              {{ item.name }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <BaseInput rules="required">
          <BaseRadio v-model="item.required" :name="true">
            Cet article est obligatoire
          </BaseRadio>
          <BaseRadio v-model="item.required" :name="false">
            Cet article est facultatif
          </BaseRadio>
        </BaseInput>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <BaseInput
          v-model="item.suggestedQuantity"
          type="number"
          label="Quantité suggérée"
          rules="required"
        />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="6">
        <BaseInput
          v-model="item.neededStudentsQuantity"
          type="number"
          label="Nombre d’élèves qui devront se procurer cet article"
          rules="required"
        />
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <BaseButton type="default" size="sm" native-type="submit">
          <span class="btn-inner--text">{{
            itemIsNew(item) ? 'Ajouter la suggestion' : 'Enregistrer'
          }}</span>
        </BaseButton>

        <BaseButton type="secondary" size="sm" @click.prevent="cancel">
          <span class="btn-inner--text">Annuler</span>
        </BaseButton>
      </b-col>
    </b-row>
  </BaseItemForm>
</template>
<script>
import { BaseInput, BaseRadio, BaseButton } from '@coop-zone/coop-zone-argon'
import BaseItemForm from '@/components/SchoolLists/Items/BaseItemForm'

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseItemForm,
    BaseRadio,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
  },
}
</script>
